import React from "react";

const TermsConditions = () => {
  return (
    <div>
      {" "}
      <section className="legal-page">
        <div className="container">
          <div className="section-title ">
            <h2 className="dashboard-heading title_font title_underline ai-center">
              Terms and Conditions for Routes2Roots
            </h2>
            <h4>Introduction</h4>
          </div>
          <p>
            These Application's Standard Terms and Conditions written on this
            screen shall manage your use of our app, accessible at{" "}
            <a href="https://play.google.com/store/apps/details?id=com.r2r.virsa">
              https://play.google.com/store/apps/details?id=com.r2r.virsa.
            </a>
            These Terms will be applied fully and affect to your use of this
            app. By using this app, you agreed to accept all terms and
            conditions written in here. You must not use this app if you
            disagree with any of these App's Standard Terms and Conditions.
          </p>
          <h5>Intellectual Property Rights</h5>
          <p>
            Other than the content you own, under these Terms, Routes2Roots
            and/or its licensors own all the intellectual property rights and
            materials contained in this app. You are granted limited license
            only for purposes of viewing the material contained on this app.
          </p>

          <h4>Restrictions</h4>
          <p>You are specifically restricted from all of the following:</p>

          <ul>
            <li>Publishing any app material in any other media;</li>
            <li>
              Selling, sublicensing and/or otherwise commercializing any app
              material;
            </li>
            <li>Publicly performing and/or showing any app material;</li>
            <li>
              Using this app in any way that is or may be damaging to this app;
            </li>
            <li>
              Using this app in any way that impacts user access to this app;
            </li>
            <li>
              Using this app contrary to applicable laws and regulations, or in
              any way may cause harm to the app, or to any person or business
              entity;
            </li>
            <li>
              Engaging in any data mining, data harvesting, data extracting or
              any other similar activity in relation to this app;
            </li>
          </ul>
          <h4>Using this app to engage in any advertising or marketing.</h4>
          <p>
            Certain areas of this app are restricted from being access by you
            and Routes2Roots may further restrict access by you to any areas of
            this app, at any time, in absolute discretion. Any user ID and
            password you may have for this app are confidential and you must
            maintain confidentiality as well..
          </p>
          <h5>Your Content</h5>
          <p>
            In these App's Standard Terms and Conditions, "Your Content" shall
            mean any audio, video text, images or other material you choose to
            display on this app. By displaying Your Content, you grant
            Routes2Roots a non-exclusive, worldwide irrevocable, sub licensable
            license to use, reproduce, adapt, publish, translate and distribute
            it in any and all media.
            <br /> Your Content must be your own and must not be invading any
            third-party’s rights. Routes2Roots reserves the right to remove any
            of Your Content from this app at any time without notice.
          </p>

          <h4>Please read Privacy Policy.</h4>
          <p>No warranties</p>
          <p>
            This app is provided "as is," with all faults, and Routes2Roots
            express no representations or warranties, of any kind related to
            this app or the materials contained on this app. Also, nothing
            contained on this app shall be interpreted as advising you.
          </p>
          <h4>Limitation of liability</h4>
          <p>
            In no event shall Routes2Roots, nor any of its officers, directors
            and employees, shall be held liable for anything arising out of or
            in any way connected with your use of this app whether such
            liability is under contract. Routes2Roots, including its officers,
            directors and employees shall not be held liable for any indirect,
            consequential or special liability arising out of or in any way
            related to your use of this app.
          </p>
          <p>
            Such data includes your device advertising identifiers, your IP
            address, cookies, pixels and other unique identifiers, data related
            to your connected Facebook profile (if any, such as your gender, age
            group, region or country), and other demographic and interests-based
            information that we or our advertising partners may have received
            from or about you, based on your consent or another legal basis
            (i.e., as required in order to provide you with our respective
            services, or based on the legitimate interests that we described in
            Section 3 above). Please note that we do not share the data you
            provide us through your connected Facebook account with our
            advertising partners.
          </p>
          <h4>Indemnification</h4>
          <p>
            You hereby indemnify to the fullest extent Routes2Roots from and
            against any and/or all liabilities, costs, demands, causes of
            action, damages and expenses arising in any way related to your
            breach of any of the provisions of these Terms.
          </p>
          <h4>Severability</h4>

          <p>
            If any provision of these Terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>
          <h4>Variation of Terms</h4>
          <p>
            Routes2Roots is permitted to revise these Terms at any time as it
            sees fit, and by using this app you are expected to review these
            Terms on a regular basis.
          </p>
          <h4>Assignment</h4>
          <p>
            The Routes2Roots is allowed to assign, transfer, and subcontract its
            rights and/or obligations under these Terms without any
            notification. However, you are not allowed to assign, transfer, or
            subcontract any of your rights and/or obligations under these Terms.
          </p>

          <h4>Entire Agreement</h4>
          <p>
            These Terms constitute the entire agreement between Routes2Roots and
            you in relation to your use of this app, and supersede all prior
            agreements and understandings.
          </p>
          <h4>Governing Law & Jurisdiction</h4>
          <p>
            These Terms will be governed by and interpreted in accordance with
            the laws of the State of in, and you submit to the non-exclusive
            jurisdiction of the state and federal courts located in in for the
            resolution of any disputes.
          </p>
          <br />
          <br />
        </div>
      </section>
    </div>
  );
};

export default TermsConditions;

import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "src/assets/donation/logo.png";
import nseLogo from "src/assets/donation/nseLogo.png";
import "./header.css";
import { height } from "@mui/system";

const Header = () => {
  const navigate = useNavigate();
  const siteUrl = "https://routes2roots.com/";
  const navItems = [
    { name: "HOME", link: "" },
    { name: "ABOUT US", link: "about-us" },
    { name: "BECOME A VOLUNTEER", link: "volunteer" },
    { name: "ACHIEVMENTS", link: "achievements" },
    { name: "MEDIA CENTER", link: "articles" },
    { name: "INVESTOR", link: "report" },
    { name: "CONTACT US", link: "contact-us" },
  ];

  return (
    <div>
      <div
        className="d-flex py-2 gap-4 justify-content-center align-items-center headerNse"
        style={{
          fontSize: "12px",
          borderBottom: "1px solid #F1F1F1",
        }}
      >
        <img
          width="80px"
          src={nseLogo}
          alt="Logo Routes 2 Roots"
          className="ms-4"
        />
        <div>Routes 2 Roots Listed On NSE's Social Stock Exchange</div>
        <a
          href="https://routes2roots.com/achievement/routes-2-roots-is-now-listed-on-social-enterprise-on-the-national-stock-exchange-nse/"
          style={{
            padding: "8px 16px",
            background: "var(--Brand-Color-Green, #00A651)",
            fontSize: "10px",
            color: "#ffffff",
            fontWeight: "bold",
          }}
        >
          Know More
        </a>
      </div>

      <header>
        <div
          className="d-flex w-100 justify-content-between align-items-center"
          style={styles.head}
        >
          <a href={siteUrl}>
            <img
              style={{ maxWidth: "200px", width: "100%" }}
              src={logo}
              alt="Logo Routes 2 Roots"
              className="ms-4"
            />
          </a>
          <ul
            className="h-100 d-flex m-0 w-100 list-unstyled align-self-center listWrap"
            style={styles.listWrap}
          >
            {navItems.map((item, index) => (
              <li key={index}>
                <a href={siteUrl + item.link}>{item.name}</a>
              </li>
            ))}
          </ul>
          <button
            className="h-100 btnDonate"
            style={styles.btnDonate}
            onClick={async () => {
              navigate("/donation", {});
            }}
          >
            DONATE NOW
          </button>
        </div>
      </header>
    </div>
  );
};

const styles = {
  head: {
    gap: "10px",
    fontSize: "1rem",
    boxShadow: "0px 2px 12px 0px #14142b14",
  },
  listWrap: {
    maxWidth: "50rem",
    justifyContent: "space-evenly",
  },
  btnDonate: {
    cursor: "pointer",
    padding: ".4rem 2.3rem",
    background: "#efc940",
  },
};

export default Header;

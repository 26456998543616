import React, {useState, useEffect} from 'react';

import expandIcon from 'src/assets/icons/down-arrow.svg';

function ParticipantList(props) {
  const [expand, setExpand] = useState(true);
  const [search, setSearch] = useState('');

  return (
    <div style={styles.container} className="jc-center">
      {/*
        <h4 align="center" style={styles.name} onClick={()=>setExpand(!expand)} className="clickable">
          Participants <img src={expandIcon} className={expand? "expand-down":"expand-up"} />
        </h4>
      */}
      <input
        placeholder={`Search Participants (${Object.keys(props.members).length-1})`}
        onChange={(e)=>{
          setSearch(e.target.value)
        }}
        className="input_sm" />
      <div style={styles.members_list}>
      {
        expand &&
          Object.keys(props.members).map((member_id,index)=>{
            if (
                //member_id !== props.selfId &&
                props.members[member_id].status === 'IN_MEETING' &&
                props.members[member_id].name.toLowerCase().includes(search.toLowerCase())
              ) {
              return (
                <div key={index} style={styles.member_container}>
                  <div style={styles.avatar}>
                    <img src={require('src/assets/icons/avatar.png').default} />
                  </div>
                  <div style={styles.name}>{props.members[member_id].name}</div>
                </div>
              )
            }
            else return null;
          })
      }
      </div>
    </div>
  )
}

const styles = {
  container: {
    bottom: 64,
    right: 24,
    left: 'auto',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--theme-dark)',
    padding: 16,
    borderRadius: 10,
  },
  members_list: {
    maxHeight: 200,
    overflowY: 'auto',
    marginTop: 8,
  },
  member_container: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    margin: 4,
    color: '#fff'
  },
  avatar: {
    margin: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}

export default ParticipantList;

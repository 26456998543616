import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="py-5" style={styles.footer}>
      <div className="containerF">
        <div className="row w-100">
          <div className="col w-50">
            <div class="socials_widget_logo">
              <a href="https://r2r.flyingsaints.com/" className="w-100">
                <img
                  src={require("src/assets/donation/logo.png").default}
                  alt="Footer Logo"
                  className="w-100 mb-5"
                />
              </a>
            </div>
            <ul
              className="d-flex w-100 justify-content-between"
              style={styles.social}
            >
              <li>
                <a
                  href="https://www.facebook.com/Routes2RootsNGO"
                  target="_self"
                >
                  <img
                    style={styles.socialIcon}
                    src={require("src/assets/donation/facebook.png").default}
                    alt="facebook"
                  />
                </a>
              </li>

              <li>
                <a href="https://twitter.com/Routes2RootsNGO" target="_self">
                  <img
                    style={styles.socialIcon}
                    src={require("src/assets/donation/twitter.png").default}
                    alt="Twitter"
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/m/company/routes2roots"
                  target="_self"
                >
                  <img
                    style={styles.socialIcon}
                    src={require("src/assets/donation/linkedin.png").default}
                    alt="linkedin"
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://www.youtube.com/channel/UCJIRmbh4kyTLhlVB2-jjZkw"
                  target="_self"
                >
                  <img
                    style={styles.socialIcon}
                    src={require("src/assets/donation/youtube.png").default}
                    alt="Youtube"
                  />
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/Routes2RootsNGO/"
                  target="_self"
                >
                  <img
                    style={styles.socialIcon}
                    src={require("src/assets/donation/insta.png").default}
                    alt="Instagram"
                  />
                </a>
              </li>
            </ul>
          </div>

          <div className="col">
            <h6 style={styles.widgetTitle}>Contacts</h6>
            <div>
              <p>
                Corporate Office: G2, Max Towers, Delhi One, Sector-16 B, Gautam
                Buddh Nagar, Noida, Uttar Pradesh-201301, India
              </p>
              <p>
                Phone:
                <a href="tel:+91-120-4124949"> +91-120-4124949 </a>
              </p>

              <p>
                Email:
                <a href="mailto:info@routes2roots.com">info@routes2roots.com</a>
              </p>
            </div>
          </div>

          <div id="nav_menu-4" className="col">
            <h6 style={styles.widgetTitle}>Menu &amp; Links</h6>
            <div class="menu-prefooter-menu-container">
              <ul class="menu">
                <li class="menu-item">
                  <a href="https://routes2roots.com/" aria-current="page">
                    Home Page
                  </a>
                </li>
                <li class="menu-item">
                  <a href="https://routes2roots.com/report/">Report</a>
                </li>
                <li class="menu-item">
                  <a href="https://routes2roots.com/achievements/">
                    Achievements
                  </a>
                </li>
                <li class="menu-item">
                  <a href="https://routes2roots.com/contact-us/">Contact Us</a>
                </li>
                <li class="menu-item">
                  <a href="https://routes2roots.com/impact/">Impact</a>
                </li>
                <li class="menu-item">
                  <a href="https://routes2roots.com/about-us/">About Us</a>
                </li>
                <li class="menu-item">
                  <a href="https://routes2roots.com/volunteer/">Volunteer</a>
                </li>
                <li class="menu-item">
                  <a href="https://routes2roots.com/terms-and-condition/">
                    T&amp;C
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col">
            <h6 style={styles.widgetTitle}>Contribute For a Good Cause</h6>
            <div class="helpo_donation">
              <a class="helpo_button" href="https://r2rvirsa.com/donation">
                Donate Now
              </a>
              <p>to make a difference</p>
            </div>
          </div>
        </div>
      </div>
      <div style={styles.helpoFooter}>
        <div className="containerF">
          <div style={styles.copyWrite}>© 2024 Routes2Roots</div>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#20212b",
    lineHeight: "32px",
    color: "#a9a9ab",
  },
  footerSText: {
    fontSize: "0.7rem",
  },
  newsBtn: {
    width: "50%",
    textAlign: "center",
    padding: "0.2rem 1.2rem",
    fontWeight: 700,
    fontSize: "0.9rem",
    cursor: "pointer",
    border: "2px solid #efc940",
    borderRadius: "50px",
  },
  footerTitle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "-1px",
    color: "#ffffff",
    marginBottom: "0.9rem",
  },
  footerText: {
    fontSize: "0.9rem",
    display: "inline",
    lineHeight: "27.2px",
    padding: "0.3rem 0rem",
  },
  donateBtn: {
    width: "100%",
    textAlign: "center",
    padding: "0.5rem 1rem",
    background: "#efc940",
    borderRadius: "50rem",
    margin: "1rem 0rem",
    fontWeight: 700,
    fontSize: "12px",
    cursor: "pointer",
    color: "#333333",
  },
  social: {
    listStyle: "none",
    margin: "0px",
    padding: "0px",
  },
  socialIcon: {
    width: "1.5rem",
    height: "1.5rem",
  },
  widgetTitle: {
    boxSizing: "border-box",
    marginTop: 0,
    wordWrap: "break-word",
    fontWeight: "700",
    textTransform: "none",
    lineHeight: "1.2",
    fontSize: "20px",
    marginBottom: "20px",
    color: "#ffffff",
  },
  helpoFooter: {
    borderTop: "1px solid #2c2d37",
  },
  copyWrite: {
    fontSize: "12px",
  },
};

export default Footer;

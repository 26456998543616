import axios from "axios";
import React, { useEffect, useState } from "react";
import Video from "../components/Video";
import { auth, BASE_URL } from "../config";

const LiveStreaming = () => {
  const [streamData, setstreamData] = useState("");

  useEffect(() => {
    const url = `${BASE_URL}/api/v1/resources`;
    const headers = { Authentication: auth };
    axios
      .get(url, { headers })
      .then((response) => setstreamData(response.data));
  }, []);
  console.log(streamData);

  return <div>{/* <Video data={ } /> */}</div>;
};

export default LiveStreaming;

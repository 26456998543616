import React from "react";
import styles from "../../style/navbarAccordion.module.css";
import facebook from "../../assets/accordion/facebook.svg";
import twitter from "../../assets/accordion/twitter.svg";
import linkedin from "../../assets/accordion/linkedin.svg";
import playButton from "../../assets/accordion/play-button.svg";
import user from "../../assets/accordion/user.png";
import { NavLink, useNavigate } from "react-router-dom";
import { loadUserDataFromSession } from "../../hooks/session-data";

const NavbarAccordion = () => {
  const navigate = useNavigate();
  const name = loadUserDataFromSession("r2rUserName");
  return (
    <div className={styles.accordion}>
      <div className={styles.accordionHeader}>
        <img width="64px" heigth="64px" src={user} />
        <div>
          <div className={styles.profileTittle}>Hello</div>
          <div className={styles.profileName}>
            {name ? name.toUpperCase() : "User"}
          </div>
        </div>
      </div>

      <div className={styles.accordionBody}>
        <ul>
          <NavLink
            to="/user/home"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li>Home</li>
          </NavLink>
          <NavLink
            to="/user/live-streaming"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li>Live Streaming</li>
          </NavLink>
          <NavLink to="/join">
            <li>Join Class</li>
          </NavLink>
          <NavLink
            to="/user/class-curriculum"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li>Class Curriculum</li>
          </NavLink>
          <NavLink
            to="/user/class-schedule"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li>Class Schedule</li>
          </NavLink>
          <NavLink
            to="/user/contact-us"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li>Contact Us</li>
          </NavLink>
          <NavLink
            to="/user/about-us"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li>About Us</li>
          </NavLink>
          <NavLink
            to="/user/feedback"
            className={({ isActive }) => (isActive ? styles.activeLink : "")}
          >
            <li>Feedback</li>
          </NavLink>
        </ul>
      </div>
      <div className={styles.accordionBottom}>
        <ul>
          <li>
            <a
              href="https://www.facebook.com/Routes2RootsNGO"
              target="_blank"
              className="twitter"
            >
              <img width="30px" height="30px" src={facebook} />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/Routes2RootsNGO"
              target="_blank"
              className="facebook"
            >
              <img width="30px" height="30px" src={twitter} />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/mwlite/company/routes2roots"
              target="_blank"
              className="linkedin"
            >
              <img width="30px" height="30px" src={linkedin} />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCJIRmbh4kyTLhlVB2-jjZkw"
              className="instagram"
            >
              <img width="30px" height="30px" src={playButton} />
            </a>
          </li>
        </ul>
      </div>
      <div>
        <ul className={styles.terms_list}>
          <li onClick={() => navigate("/user/terms-conditions")}>
            Terms and Conditions{" "}
          </li>
          <li onClick={() => navigate("/user/privacy-policy")}>
            Private Policy{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarAccordion;

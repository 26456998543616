import React from "react";
import { saveUserDataInSession } from "../../hooks/session-data";

const Settings = (props) => {
  const [reloadFlag, setReloadFlag] = React.useState(false);
  const [preferences, setPreferences] = React.useState({});
  const changePreferences = (key, value) => {
    const obj = { ...preferences };
    obj[key] = value;
    setPreferences(obj);
    setReloadFlag(true);
  };
  const [devices, setDevices] = React.useState({
    audio: [],
    video: [],
  });
  React.useEffect(() => {
    //console.log(props.config);
    setPreferences(props.config.preferences);
    let obj = { ...devices };
    obj.audio = [];
    obj.video = [];
    props.config.devices.map((device, i) => {
      if (device.kind === "audioinput") {
        let deviceId = device.deviceId;
        let label = device.label || `Microphone ${obj.audio.length + 1}`;
        obj.audio.push({ label, deviceId });
      } else if (device.kind === "videoinput") {
        let deviceId = device.deviceId;
        let label = device.label || `Camera ${obj.audio.length + 1}`;
        obj.video.push({ label, deviceId });
      }
    });
    setDevices(obj);
  }, [props.config]);

  return (
    <div style={styles.container}>
      {props.open && (
        <div style={{ position: "absolute", bottom: 64 }}>
          <div className="elavated card">
            <h5>Select input devices</h5>
            <label>Camera</label>
            <select
              id="video_input"
              onChange={(e) => changePreferences("video_input", e.target.value)}
              value={preferences.video_input || "default"}
            >
              {devices.video.map((device, i) => (
                <option key={i} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
            <label>Microphone</label>
            <select
              id="audio_input"
              onChange={(e) => changePreferences("audio_input", e.target.value)}
              value={preferences.audio_input || "default"}
            >
              {devices.audio.map((device, i) => (
                <option key={i} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
            <div className="spacing_sm" />
            {reloadFlag && (
              <button
                type="primary"
                onClick={() => {
                  saveUserDataInSession(
                    "preferences",
                    JSON.stringify(preferences)
                  );
                  window.location.reload();
                }}
              >
                Apply & Reload
              </button>
            )}
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
};

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default Settings;

import axios from "axios";
import React, { useEffect, useState } from "react";
import Video from "../../../components/Video";
import { BASE_URL } from "../../../config";

const TeacherClasses = () => {
  const [teacherClassesList, setTeacherClassesList] = useState([]);

  useEffect(() => {
    const url = `${BASE_URL}/api/v1/online-teachers-training`;
    axios
      .get(url)
      .then((response) => {
        setTeacherClassesList(response.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <h2 className="dashboard-heading title_font title_underline">
        TEACHER'S TRAINING
      </h2>
      <div
        className="jc-center p-24"
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {teacherClassesList.map((data, index) => (
          <div
            className="card-plan elevated_deep m-12 ai-center bg-black"
            style={{ width: 450, position: "relative", overflow: "hidden" }}
            key={index}
          >
            <Video data={data.class_link} />
            <div style={style.title}>
              <h5 className="h5_font_family">{data.name}</h5>
              <div style={{ fontSize: "13px", color: "#c2c2c2" }}>
                {data.label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const style = {
  title: {
    padding: 15,
    background: " white",
    position: "relative",
    zIndex: 3,
    textAlign: "center",
    width: "100%",
    height: "100%",
    fontSize: 18,
  },
};
export default TeacherClasses;

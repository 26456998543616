import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  clearUserSession,
  saveUserDataInSession,
  loadUserDataFromSession,
} from "../hooks/session-data";

const GuestLogin = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");

  async function handleUser() {
    let user = await loadUserDataFromSession("user");
    if (user) {
      const { name, userId } = JSON.parse(user);
      setName(name);
      setUserId(userId);
    }
    clearUserSession("user");
  }

  useEffect(() => {
    handleUser();
  }, []);

  return (
    <div className="bg-container ai-center jc-center" style={styles.container}>
      <img
        src={require("src/assets/app_logo.png").default}
        style={styles.app_logo}
      />
      <div class="mainCard">
        <div class="imgWrap" style={styles.wrapImg}>
          <img
            src={require("src/assets/Finl.jpg").default}
            alt=""
            style={styles.image}
          />
        </div>
        <div className="card ai-center elevated">
          <h1>Login as Guest</h1>
          <h5 className="spacing_sm" align="center">
            Enter your Name & Email to join Class
          </h5>
          <br />
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input"
            placeholder="Full Name / Institute Name"
            style={styles.input}
          />
          <input
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            className="input"
            placeholder="Email Address"
            style={styles.input}
          />
          <button
            style={styles.button}
            type={validateEmail(userId) && name ? "primary" : "disabled"}
            onClick={async () => {
              if (validateEmail(userId) && name) {
                await saveUserDataInSession(
                  "user",
                  JSON.stringify({ name, userId })
                );
                navigate("/join");
              }
            }}
          >
            Join Class
          </button>
          <button
            style={styles.button_2}
            type={"secondry"}
            onClick={async () => {
              navigate("/", {
                state: {
                  verification: false,
                  guest: false,
                },
              });
            }}
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    overflow: "hidden",
    backgroundColor: "#E4E4E4",
  },
  input: {
    marginTop: 12,
  },
  button: {
    width: "100%",
    marginTop: 32,
    marginBottom: 8,
  },
  button_2: {
    width: "100%",
    marginTop: 8,
    marginBottom: 8,
  },
  header: {
    display: "flex",
    fontSize: 24,
  },
  app_logo: {
    position: "absolute",
    top: 32,
    right: 32,
    height: 32,
  },
  wrapImg: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
  },
};

const validateEmail = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default GuestLogin;

import { TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { BASE_URL } from "../../config";
import styles from "../../style/dashboard.module.css";

const EditNumberEmail = () => {
  const initialValue = {
    email: "",
    number: "",
  };
  const [formInput, setFormInput] = useState(initialValue);
  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `${BASE_URL}/api/v1/update-number/`;
    axios
      .post(url, formInput)
      .then((response) => {
        setFormInput(initialValue);
      })
      .catch((err) => console.log(err));
    console.log(formInput);
  };

  return (
    <form>
      <div
        className=" card elevated_deep"
        style={{ width: 500, margin: "100px auto" }}
      >
        <TextField
          id="number"
          label="Phone Number"
          variant="filled"
          name="number"
          value={formInput.number}
          onChange={handleChange}
        />
        <div onClick={handleSubmit} className={styles.contact_button}>
          {" "}
          EDIT NUMBER / EMAIL
        </div>
      </div>
    </form>
  );
};

export default EditNumberEmail;

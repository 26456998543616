import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="legal-page">
      <div className="container">
        <div className="section-title">
          <h2 className="dashboard-heading title_font title_underline">
            Privacy Policy
          </h2>
          <h4>Introduction</h4>
        </div>
        <p>
          We are strongly committed to making our Privacy practices more
          transparent and fair. This Privacy Notice describes how we collect,
          store, use and disclose personal data regarding individuals who use
          our app ("Users" or "you"), and how we process personal data you
          provide concerning your contacts and recipients ("Recipients"), and
          our role as "Data Processor" of such data. It applies whenever you use
          our Platform, interact with us or otherwise access or use any of our
          Services.
        </p>

        <h5>Specifically, it describes our practices regarding –</h5>
        <ul>
          <li>Data Collection</li>
          <li>Data Uses</li>
          <li>Cookies, Tracking and Advertising Uses</li>
          <li>Storage and Retention</li>
          <li>Data Sharing</li>
          <li>Communications</li>
          <li>Data Security</li>
          <li>Data Subject Rights</li>
          <li>Children</li>
          <li>Updates and How to contact us</li>
        </ul>

        <p>
          We strongly urge you to read this notice and make sure that you fully
          understand and agree to it. If you do not agree to this notice, please
          discontinue and avoid using our Services.
        </p>
        <p>
          You are not legally required to provide us with any Personal Data, but
          without it we will not be able to provide you with the full range or
          with the best experience of using our Services (for instance, you may
          still use parts of our Platform without signing up, but you will not
          be able to enjoy all of its features).
        </p>

        <h4>1. Data Collection</h4>
        <p>
          We collect four main categories of data (and to the extent it may
          enable the identification of a specific person, or is linked to such
          potentially identifying data, we will deem it as "Personal Data"):
        </p>

        <ul>
          <li>
            Data automatically collected or generated: when someone visits,
            interacts with or uses our Services, including any e-mail or text
            messages sent to them by us or via our Services, we may collect or
            generate technical data about them. We collect or generate such data
            either independently or with the help of third party services (as
            detailed in Sections 4 and 5 below).
            <p>
              Such data consists of connectivity, technical or aggregated usage
              data, such as IP address, device advertiser ID, carrier and
              networks, non-identifying data regarding the device, operating
              system, browser version, locale and language settings used, the
              cookies and pixels installed on such device, and the activity
              (clicks and other interactions) of Users of our Services.
            </p>
          </li>
          <li>
            Data received from you: you may provide us Personal Data
            voluntarily, such as your name and e-mail address when you contact
            us, your hashed password, your content and images (including details
            regarding your events or other announcements, if you choose to
            upload them to the Services), when you post on our public forums or
            groups, when you sign-up to receive e-mail updates, when you place
            any purchases via our Services, or when you choose to connect your
            Facebook account to our Services.
            <p>
              You may also provide us with Personal Data of your Recipients,
              namely their names and e-mail addresses, so that you could use our
              Services to contact and send them content you uploaded to and
              created through the Services.
            </p>
          </li>
          <li>
            Data received from Facebook and other channels: If you choose to
            connect your Facebook account to our Services, we will receive
            access to your full name and user ID on Facebook. You may also
            choose to allow us to access your images saved on Facebook, so that
            you could fetch these images and use them on our Services. Such
            access will also allow us to collect the following parameters (to
            the extent you defined them as "public"): your profile picture,
            gender, date of birth, comments on our Site, tags and tagged
            pictures, locale and time zone.
            <p>
              In addition, we or our advertising partners may receive from
              Facebook and our other marketing channels general information
              concerning the performance of our advertising campaigns, such as
              the targeted age group or interests, and we or our partners may be
              able to link such general data to any other data in our possession
              (to learn more about our advertising practices, please read
              Section 4 below).
            </p>
          </li>
        </ul>

        <h4>2. Data Uses</h4>
        <p>
          We will use your Personal Data as necessary for the performance of our
          Services; for complying with applicable law; and based on our
          legitimate interests in maintaining and improving our Services and
          offerings, understanding how our Services are used, serving effective
          advertisements on our Site and through other channels, improving our
          customer service and support operations, and protecting and securing
          our Users, ourselves and our Services.
        </p>

        <h5>Accordingly, we use Personal Data for the following purposes:</h5>
        <ul>
          <li>To facilitate, operate, and provide our Services;</li>
          <li>
            To authenticate the identity of our Users, and allow them access to
            additional features;
          </li>
          <li>
            To provide our Users with customer care, assistance and technical
            support services;
          </li>
          <li>
            To further develop, customize and improve the Services and your user
            experience, based on common or personal preferences, experiences and
            difficulties;
          </li>
          <li>
            To manage and deliver advertisements more effectively, including
            contextual, behavioral and interests-based advertising based on your
            activity on our Site and Platform, and based on your preferences or
            other data available to us or to our business partners, including
            for re-targeting purposes;
          </li>
          <li>
            To contact our Users (via e-mail, Facebook, push notifications or
            any other available channels) with general or personalized
            service-related messages (such as confirmations or system
            maintenance notices); to contact our Users with promotional messages
            (such as updates regarding new features and services, etc.); and to
            facilitate, sponsor and offer certain events and promotions;
          </li>
          <li>
            To support and enhance our data security measures, including for the
            purposes of preventing and mitigating the risks of fraud, error or
            any illegal or prohibited activity;
          </li>
          <li>
            To create aggregated statistical data, inferred non-personal data or
            anonymized or pseudonymized data (rendered non-personal), which we
            or our business partners may use to provide and improve our
            respective services; and
          </li>
          <li>To comply with any applicable laws and regulations.</li>
        </ul>
        <h4>3. Cookies, Tracking and Advertising Uses</h4>
        <p>
          Our Services and some of our Service Providers utilize “cookies”,
          anonymous identifiers and other tracking technologies which help us
          provide and improve our Services, personalize your experience and
          monitor the performance of our activities and campaigns, as well as
          the usage of our Services as a whole. A “cookie” is a small text file
          that is used, for example, to collect data about activity on our Site.
          Some cookies and other technologies serve to recall Personal Data,
          such as an IP address, previously indicated by the User.
        </p>

        <p>
          Our Services also utilize advertising for third party services and
          products. We and our advertising partners use certain data about you
          in order to improve our advertising practices and make the ads we
          deliver more effective, relevant and enjoyable, by deploying
          contextual and interest-based advertising, as well as targeting, ad
          measurement and attribution systems. The ads we or our advertising
          partners deliver may be shown within our Services or through other
          channels, such as third party websites and applications.
        </p>

        <p>
          Such data includes your device advertising identifiers, your IP
          address, cookies, pixels and other unique identifiers, data related to
          your connected Facebook profile (if any, such as your gender, age
          group, region or country), and other demographic and interests-based
          information that we or our advertising partners may have received from
          or about you, based on your consent or another legal basis (i.e., as
          required in order to provide you with our respective services, or
          based on the legitimate interests that we described in Section 3
          above). Please note that we do not share the data you provide us
          through your connected Facebook account with our advertising partners.
        </p>

        <p>
          Please note that this Privacy Notice does not apply to the processing
          of your data by our advertising partners and networks.
        </p>
        <strong>
          How to adjust your preferences regarding cookies and interest-based
          advertising
        </strong>

        <p>
          While we do not change our practices in response to a “Do Not Track”
          signal in the HTTP header from a browser, most browsers allow you to
          control cookies, including whether or not to accept them and to remove
          them. You may set most browsers to notify you if you receive a cookie,
          or you may choose to block cookies with your browser.
        </p>

        <p>
          Please note that if you reset your cookies or opt-out of interest
          based advertising, you may still see advertisements in our Services,
          or on any other channels, but those ads will not be targeted based on
          the above mentioned identifiers. For example, such ads may be instead
          based on context, such as your activity on our Services, or your
          language preferences. Please also note that such actions may result in
          a less enjoyable user experience.
        </p>

        <h4>4. Storage and Retention</h4>
        <p>
          Your Personal Data may be maintained, processed, accessed and stored
          by R2R Virsa and our authorized affiliates, Service Providers and
          business partners in and from and other jurisdictions, as necessary
          for the proper delivery of our Services, or as may be required by law.
          <br />
          While privacy laws may vary between jurisdictions, R2R Virsa has taken
          reasonable steps to ensure that your Personal Data is treated by its
          affiliates and Service Providers in a secure and lawful manner, and in
          accordance with common industry practices, regardless of any lesser
          legal requirements that may apply in their jurisdiction.
          <br />
          We retain your Personal Data for the period necessary in order to
          maintain and expand our relationship, and to provide you with our
          Services. In other words, we will retain your Personal Data for as
          long as you remain our User and have not notified us otherwise (or
          deleted your account, as described in Section 8). We will also retain
          your Personal Data for legal and accounting purposes (i.e., as
          required by laws applicable to our record and bookkeeping, and in
          order to have proof and evidence concerning our relationship, should
          any legal issues arise following your discontinuance of use), all in
          accordance with our data retention policy. If you have any questions
          about our data retention policy, please contact us at{" "}
          <a href="mailto:info@r2rvirsa.com">info@r2rvirsa.com</a>.
          <br />
          Please note that except as required by applicable law, we will not be
          obligated to retain your data for any particular period, and are free
          to securely delete it for any reason and at any time, with or without
          notice to you.
        </p>

        <h4>5. Data Sharing</h4>
        <p>
          We may share your data with certain third parties, including law
          enforcement agencies, our service providers and our affiliates – but
          only in accordance with this Notice:
        </p>

        <ul>
          <li>
            Compliance with Laws, Legal Orders and Authorities: We may disclose
            or allow government and law enforcement officials access to certain
            Personal Data, in response to a subpoena, search warrant or court
            order (or similar requirement), or in compliance with applicable
            laws and regulations, including for national security purposes. Such
            disclosure or access may occur with or without notice to you, if we
            have a good faith belief that we are legally compelled to do so, or
            that disclosure is appropriate in connection with efforts to
            investigate, prevent, or take action regarding actual or suspected
            illegal activity, fraud, or other wrongdoing.
          </li>
          <li>
            Service Providers and Business Partners: We may engage selected
            third party companies and individuals to perform services
            complementary to our own (e.g. hosting services, data analytics
            services, marketing agencies and advertising services and tools,
            data and cyber security services, fraud detection and prevention
            services, user engagement services, e-mail distribution and
            monitoring services, session recording services, and our business,
            legal, financial and compliance advisors) (collectively, "Service
            Providers"). These Service Providers may have access to your
            Personal Data, depending on each of their specific roles and
            purposes in facilitating and complementing our Services, and may
            only use it for such purposes.
          </li>
          <li>
            Third Party Integrations: The Services allow you integrate with
            certain third party services, such as in order to connect your
            Facebook account to the Platform, in which case you will be bound by
            the terms of service and privacy notices of said third parties
            (e.g., Facebook) – so please make sure that you read and accept them
            in advance. We do not receive or store your passwords for any of
            these third party services.
          </li>
          <li>
            Protecting Rights and Safety: We may share your Personal Data with
            others, if we believe in good faith that this will help protect the
            rights, property or personal safety of R2R Virsa, any of our Users
            or any members of the general public.
          </li>
          <li>
            R2R Virsa Subsidiaries and Affiliated Companies: We may share
            Personal Data internally within our family of companies, for the
            purposes described in this Notice. In addition, should R2R Virsa or
            any of its affiliates undergo any change in control, including by
            means of merger, acquisition or purchase of substantially all of its
            assets, your Personal Data may be shared with the parties involved
            in such event. If we believe that such change in control might
            materially affect your Personal Data then stored with us, we will
            notify you of this event and the choices you may have via e-mail
            and/or prominent notice on our Services.
          </li>
          <li>
            For the removal of doubt, R2R Virsa may share your Personal Data in
            additional manners, pursuant to your explicit approval, if we are
            legally obligated to do so, or if we have successfully rendered such
            data non-personal and anonymous. We may share or otherwise use
            non-personal data in our sole discretion and without the need for
            further approval.
          </li>
        </ul>
        <h4>6. Communications</h4>
        <p>
          Service Communications: we may contact you with important information
          regarding our Services. For example, we may notify you (through any of
          the means available to us) of changes or updates to our Services,
          issues, etc. You will not be able to opt-out of receiving such service
          communications.
          <br />
          Promotional Communications: we may also notify you about new services,
          events and special opportunities or any other information we think you
          will find valuable. We will provide such notices through any of the
          contact means available to us (e.g. phone, mobile or e-mail), through
          the Services, or through our marketing campaigns on any other sites or
          platforms. If you wish not to receive such promotional communications,
          you may notify R2R Virsa at any time by e-mailing us at{" "}
          <a href="mailto:info@r2rvirsa.com">info@r2rvirsa.com</a> or by
          following the "unsubscribe", "stop" or "change e-mail preferences"
          instructions in the promotional communications you receive.
        </p>

        <h4>7. Data Security</h4>
        <p>
          In order to protect your Personal Data held with us and our Service
          Providers, we are using industry-standard physical, procedural and
          electronic security measures, including encryption where deemed
          appropriate. However, please be aware that regardless of any security
          measures used, we cannot and do not guarantee the absolute protection
          and security of any Personal Data stored with us or with any third
          parties.
        </p>

        <h4>8. Data Subject Rights</h4>
        <p>
          If you wish to exercise your rights under applicable to access,
          rectify, erase or port your Personal Data held with R2R Virsa, or to
          revoke the authorization you granted R2R Virsa to access your Facebook
          account – please contact us at{" "}
          <a href="mailto:info@r2rvirsa.com">info@r2rvirsa.com</a>.
          <br />
          Please note that once you contact us by e-mail, we may require
          additional information and documents, including certain Personal Data,
          in order to authenticate and validate your identity and to process
          your request. Such additional data will be then retained by us for
          legal purposes (e.g. so we have proof of the identity of the person
          submitting the request), in accordance with our data retention policy.
          <br />
          Please note that in some cases, as we may rely on our Service
          Providers' APIs and systems, your requests may require up to a few
          weeks to process.
        </p>

        <h4>9. Children</h4>
        <p>
          Our Services are not designed to attract children under the age of 16.
          We do not knowingly or intentionally collect Personal Data from
          children and do not wish to do so. If we learn that a child is using
          the Services, we will prohibit and block such use and will make all
          efforts to promptly delete any Personal Data stored with us with
          regard to such child. If you believe that we might have any such data,
          please contact us at{" "}
          <a href="mailto:info@r2rvirsa.com">info@r2rvirsa.com</a>.
        </p>

        <h4>10. Updates &amp; How To Contact Us</h4>
        <p>
          Updates and amendments: We may update and amend this Notice from time
          to time by posting an amended version on our Services. The amended
          version will be effective as of the published effective date. We will
          provide an advance notice if any substantial changes are involved, via
          any of the communication means available to us, or on the Services.
          After this notice period, all amendments shall be deemed accepted by
          you.
          <br />
          Questions, Concerns or Complaints: If you have any comments or
          questions about this Privacy Notice or if you have any concerns
          regarding your Privacy, please contact us at{" "}
          <a href="mailto:info@r2rvirsa.com">info@r2rvirsa.com</a>
        </p>
        <br />
      </div>
    </section>
  );
};

export default PrivacyPolicy;

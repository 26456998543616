import React from "react";

const AboutUs = () => {
  return (
    <div>
      <section className="legal-page">
        <div className="container">
          <div className="section-title ">
            <h2 className="dashboard-heading title_font title_underline">
              About Virsa
            </h2>
          </div>
          <p>
            Virsa is a cultural education initiative of Routes 2 Roots, which is
            the first of its kind in the world providing cultural education to
            students through the digital medium with the aim of making the
            future generations aware of their heritage thereby strengthening the
            social and cultural fabric of the country and the world.
          </p>
          <p>
            Virsa addresses the need to make the young generation aware of their
            cultural inheritance and therefore is an integral part of education
            which needs to be passed down from generation to generation. It also
            aids the holistic development of students making them balanced
            individuals. Most of the countries have made culture an integral
            part of education.{" "}
          </p>
          <p>
            The teachers and gurus who form the teaching faculty of Virsa have
            been selected from the country’s renowned music and art schools and
            are leading exponents of their respective art forms. Under Virsa
            children get an opportunity to learn from the celebrated masters of
            performing arts, which is a distant dream for most children.
          </p>
          <p>
            Virsa has at its core, an <strong>advisory board</strong> of highly
            accomplished and world renowned artistes and legends from various
            disciplines of performing arts. These include Pandit Birju Maharaj,
            Ustad Amjad Ali Khan, Dr L Subramaniam, Pandit Rajan & Sajan Mishra,
            Pandit Vishwa Mohan Bhatt, Shovana Narayan, Kavita Krishnamurthy,
            Shafqat Amanat Ali, Ehsaan Noorani, Juhi Chawla, Guru Saroja
            Vaidyanathan, Kiran Segal, Amaan Ali Bangash, Rakesh Chaurasia,
            Ayaan Ali Bangash, Kaushiki Chakrabarty, Shubhendra Rao, Rajnish and
            Ritesh Mishra, and Akram Khan.
          </p>
          <h4>Live Interactive Classes</h4>
          <p>
            The program has daily real-time interactive classNamees (2-3
            classNamees daily) teaching 15 disciplines of performing arts
            including Hindustani Classical Vocal, Harmonium, Tabla, Carnatic
            Classical Vocal, Sitar, Flute, Keyboards, Guitar, Kathak,
            Bharatnatyam, Theatre, Yoga, Punjabi Literature and Culture, Fun
            with Maths and Stress Management through NLP. The classNamees are
            held as per a pre-decided schedule and a specially designed (by
            experts) curriculum is followed for all the 15 disciplines.
          </p>
          <p>
            The project encompasses schools of all income groups and makes the
            classNamees available absolutely free of cost to all strata of
            society. These classNamees are also archived for revision and
            reference purposes and made available to enrolled students.
          </p>
          <h4>School Workshops</h4>
          <p>
            An important aspect of the program is the live workshops conducted
            by the teachers, who physically visit schools at regular intervals
            to hold workshops with students, thereby generating and enhancing
            connectivity between students and teachers in their specific
            disciplines.
          </p>
          <h4>Competitions</h4>
          <p>
            Virsa also gives students the opportunity to showcase their talent
            before the esteemed teachers of the faculty by organizing All India
            Inter School competitions, which are open to all the students of the
            program across India and overseas. These competitions, organized
            twice a year see thousands of students participate each year.
            Entries are judged by Virsa teachers and winners are awarded to
            motivate them further, while all participants are given certificates
            to ensure they cultivate a healthy sense of participation and are
            inspired to learn and hone their skills.
          </p>
          <p>
            Virsa is a worldwide endeavour by Routes 2 Roots, which has the
            two-pronged objective of keeping the cultural heritage alive and
            empowering the coming generations by enriching their holistic growth
            and development.
          </p>
          <br />
        </div>
      </section>
    </div>
  );
};

export default AboutUs;

const CryptoJS = require("crypto-js");

const secretKey = "1235790qwertuop"; // Store it in an environment variable

if (!secretKey) {
  throw new Error("Encryption secret key not available.");
}

// Take an object and return encrypted data
function encryptData(data) {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  return encryptedData;
}

// Take encrypted data and return decrypted data
function decryptData(encryptedData) {
  if (encryptedData) {
    try {
      const newData = encryptedData.replaceAll('"', "");
      const decryptedBytes = CryptoJS.AES.decrypt(newData, secretKey);
      if (!decryptedBytes) {
        throw new Error("Decryption failed");
      }
      const decryptedData = JSON.parse(
        decryptedBytes.toString(CryptoJS.enc.Utf8)
      );
      return decryptedData;
    } catch (error) {
      throw new Error("Decryption error: " + error.message);
    }
  } else {
    throw new Error("Encrypted data not provided.");
  }
}

module.exports = {
  encryptData,
  decryptData,
};

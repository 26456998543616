import React from "react";

const HeroSection = () => {
  return (
    <section>
      <div className="position-relative heroWrap">
        <img
          src={require("src/assets/donation/image.jpeg").default}
          alt="Cinque Terre"
          style={styles.image}
        />
        <div
          className="position-absolute d-flex align-items-center"
          style={styles.positionHero}
        >
          <h1 style={styles.heroTitle}>
            Your Donation <br />
            <span style={styles.heroSpan}>Makes a Difference</span>
          </h1>
        </div>
        <div
          className="position-absolute fontStorytella"
          style={styles.donateTitle}
        >
          Donate
        </div>
      </div>
    </section>
  );
};

const styles = {
  image: {
    width: "100%",
    height: "34rem",
    objectFit: "cover",
    objectPosition: "top center",
  },
  positionHero: {
    top: 0,
    bottom: 0,
    width: "92%",
    height: "34rem",
    backgroundColor: "#2d2d2d66",
    paddingLeft: "8%",
  },
  heroTitle: {
    fontSize: "4.4rem",
    fontWeight: 700,
    lineHeight: "80px",
    letterSpacing: "-3.5px",
    textAlign: "left",
    color: "#ffffff",
  },
  heroSpan: {
    fontWeight: 300,
  },
  donateTitle: {
    bottom: 0,
    right: "5%",
    color: "#EFC940",
    fontSize: "12.5rem",
    fontWeight: 400,
    lineHeight: "8rem",
  },
};

export default HeroSection;

import { encryptData, decryptData } from "./encDecData";

// Store data in session storage
export const saveUserDataInSession = function (key, user) {
  var userData = encryptData(user);
  try {
    sessionStorage.setItem(key, JSON.stringify(userData));
  } catch (error) {
    console.log(error);
  }
};

// Retrieve data from session storage if data exists, return data if it exists, if not, return false
export const loadUserDataFromSession = function (key) {
  if (typeof window === "undefined") return false;
  var userEncryptedData = sessionStorage.getItem(key);
  if (!userEncryptedData) return false;
  return decryptData(userEncryptedData);
};

// Delete particular data from session storage, return true if successful, else false
export const clearUserSession = function (key) {
  var userEncryptedData = sessionStorage.getItem(key);
  if (!userEncryptedData) return false;
  sessionStorage.removeItem(key);
  return true;
};

import React from "react";
import {
  loadUserDataFromSession,
  saveUserDataInSession,
} from "../hooks/session-data";

const config = {
  key: process.env.GUEST_ISSUER_ID,
  iss: process.env.GUEST_ISSUER_ISS,
};

export async function getAuthtoken() {
  let token = loadUserDataFromSession("guest_token");
  if (!token) {
    let user = await loadUserDataFromSession("user");
    if (!user) {
      return false;
    }
    const { name, userId } = JSON.parse(user);
    var jwt = require("jsonwebtoken");
    const payload = {
      name: name,
      sub: userId,
      iss: config.iss,
    };
    token = await jwt.sign(payload, Buffer.from(config.key, "base64"), {
      expiresIn: "3d",
    });
    saveUserDataInSession("guest_token", token);
  }
  return token;
}

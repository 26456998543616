import { TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "../../style/dashboard.module.css";

const EditProfile = () => {
  const initialValue = {
    name: "",
    number: "",
    email: "",
    subject: "",
    message: "",
  };
  const [formInput, setFormInput] = useState(initialValue);
  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormInput(initialValue);
    console.log(formInput);
  };

  return (
    <form>
      <div
        className=" card elevated_deep"
        style={{ width: 500, margin: "40px auto" }}
      >
        <TextField
          //   style={style.input}
          id="name"
          label="Name"
          variant="filled"
          name="name"
          value={formInput.name}
          onChange={handleChange}
        />
        <TextField
          //   style={style.input}
          id="number"
          label="Phone Number"
          variant="filled"
          name="number"
          value={formInput.number}
          onChange={handleChange}
        />
        <TextField
          //   style={style.input}
          id="email"
          label="Email"
          variant="filled"
          name="email"
          value={formInput.email}
          onChange={handleChange}
        />
        <TextField
          //   style={style.input}
          id="subject"
          label="Subject"
          variant="filled"
          name="subject"
          value={formInput.subject}
          onChange={handleChange}
        />
        <TextField
          //   style={style.input}
          id="message"
          label="Message"
          variant="filled"
          name="message"
          value={formInput.message}
          onChange={handleChange}
        />
        <div onClick={handleSubmit} className={styles.contact_button}>
          {" "}
          EDIT
        </div>
      </div>
    </form>
  );
};

export default EditProfile;

import React, { useState } from "react";
import { TextField } from "@mui/material";
import styles from "../style/dashboard.module.css";
import axios from "axios";
import { BASE_URL} from "../config";

const ContactUs = () => {
  const initialValue = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const [formInput, setFormInput] = useState(initialValue);
  const handleChange = (e) => {
    setFormInput((perState) => {
      return { ...perState, [e.target.name]: e.target.value };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formInput);
    axios
      .post(
        `${BASE_URL}/api/v1/contact-us/?full_name=${formInput.name}&email=${formInput.email}.com&subject=${formInput.subject}&message=${formInput.message}`
      )
      .then(function (response) {
        setFormInput(initialValue);
      })
      .catch(function (error) {
        console.log(error);
        alert("Unexpected error occured");
      });
  };

  return (
    <form>
      <div className=" card elevated_deep" style={style.inputBox}>
        <TextField
          style={style.input}
          id="name"
          label="Name"
          variant="filled"
          name="name"
          value={formInput.name}
          onChange={handleChange}
        />

        <TextField
          style={style.input}
          id="email"
          label="Email"
          variant="filled"
          name="email"
          value={formInput.email}
          onChange={handleChange}
        />
        <TextField
          style={style.input}
          id="subject"
          label="Subject"
          variant="filled"
          name="subject"
          value={formInput.subject}
          onChange={handleChange}
        />
        <TextField
          style={style.input}
          id="message"
          label="Message"
          variant="filled"
          name="message"
          value={formInput.message}
          onChange={handleChange}
        />
        <div onClick={handleSubmit} className={styles.contact_button}>
          SUBMIT
        </div>
      </div>
    </form>
  );
};
const style = {
  inputBox: {
    width: 600,
    margin: "5% auto",
    alignItems: "center",
  },
  input: {
    width: "100%",
  },
};

export default ContactUs;

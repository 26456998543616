import React, { useState, useEffect } from "react";

const Tabs = (props) => {
  const [activeKey, setActiveKey] = useState(0);
  useEffect(() => {
    props.onChange(activeKey);
  }, [activeKey]);

  return (
    <>
      <div className="tab_container">
        {(props.tabs || []).map((obj, index) => (
          <div
            key={index}
            className="clickable"
            style={
              index === activeKey ? styles.tab_label_active : styles.tab_label
            }
            onClick={() => setActiveKey(index)}
          >
            {obj.label}
          </div>
        ))}
      </div>
      {(props.tabs || [])[activeKey].child}
    </>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    borderRadius: 50,
    overflow: "hidden",
    textAlign: "center",
    margin: "auto",
  },
  tab_label_active: {
    flex: 1,
    padding: 9,
    color: "#000",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "var(--active)",
  },
  tab_label: {
    flex: 1,
    padding: 9,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "var(--primary)",
  },
};

export default Tabs;

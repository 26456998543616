import React from "react";

const ImageWrap = ({ data, dataArtist, dataCurriculum, dataSchedule }) => {
  return data ? (
    <img
      className="zoom-in"
      src={`${data}`}
      alt="img"
      style={style}
    />
  ) : dataArtist ? (
    <img
      className="zoom-in"
      src={`${dataArtist}`}
      alt="img"
      style={style}
    />
  ) : dataCurriculum ? (
    <img
      className="zoom-in"
      src={`${dataCurriculum}`}
      alt="img"
      style={style}
    />
  ) : (
    dataSchedule && (
      <img
        className="zoom-in"
        src={`${dataSchedule}`}
        alt="img"
        style={style}
      />
    )
  );
};
const style = {
  width: "100%",
  height: "100%",
};

export default ImageWrap;

import React from "react";
import { Routes, Route } from "react-router-dom";
import NavbarAccordion from "../components/navbarAccordion";
import styles from "../style/dashboard.module.css";
import Home from "./home";
import LiveStreaming from "./liveStreaming";
import Archive from "./subPages/archiveClasses";
import Classes from "./subPages/archiveClasses/classes";
import MasterClasses from "./subPages/archiveClasses/masterClasses";
import SpecialClasses from "./subPages/archiveClasses/specialClasses";
import TeacherClasses from "./subPages/archiveClasses/teacherClasses";
import Workshop from "./subPages/workshops";
import ClassCurriculum from "./classCurriculum";
import Schedule from "./schedule";
import ContactUs from "./contactUs";
import ArtistList from "./subPages/archiveClasses/regularClasses/artistList";
import DashboardNavbar from "../components/dashboardNavbar";
import AboutUs from "./aboutUs";
import FeedBack from "./feedBack";
import EditProfile from "../components/profile/editProfile";
import EditNumberEmail from "../components/profile/editNumberEmail";
import PrivacyPolicy from "./subPages/privacyPolicy";
import TermsConditions from "./subPages/termsConditions";

const Dashboard = () => {
  return (
    <>
      <div>
        <NavbarAccordion />
        <DashboardNavbar />
      </div>
      <div className={styles.dashboard}>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/live-streaming" element={<LiveStreaming />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/archive-classes" element={<Classes />} />
          <Route path="/archive-master-classes" element={<MasterClasses />} />
          <Route path="/archive-special-classes" element={<SpecialClasses />} />
          <Route
            path="/archive-teachers-classes"
            element={<TeacherClasses />}
          />
          <Route path="/workshops" element={<Workshop />} />
          <Route path="/class-curriculum" element={<ClassCurriculum />} />
          <Route path="/class-schedule" element={<Schedule />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/edit-number-email" element={<EditNumberEmail />} />
          <Route path="/artist" element={<ArtistList />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
        </Routes>
      </div>
    </>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../config";
import Video from "../../../../components/Video";
import ImageWrap from "../../../../components/ImageWrap";
import { loadUserDataFromSession } from "../../../../hooks/session-data";

const ArtistList = () => {
  const [genreName, setGenreName] = useState("");
  const [artistVideos, setArtistVideos] = useState("");

  useEffect(() => {
    const genre = loadUserDataFromSession("genre");
    const url = `${BASE_URL}/api/v1/regular-classes/${genre}`;
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.data);
        setArtistVideos(response.data.data);
      })
      .catch((err) => console.log(err));
  }, [genreName]);

  return (
    <>
      <h2 className="dashboard-heading title_font title_underline">
        {loadUserDataFromSession("label").toUpperCase()}
      </h2>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {!artistVideos ? (
          <br />
        ) : (
          <>
            {artistVideos.map((data, index) => (
              <div
                key={index}
                className="jc-center p-12"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="card-plan elevated_deep ai-center bg-black"
                  style={{
                    width: 450,
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <Video data={data.class_link} />
                  <h5 className="h5_font_family" style={style.data}>
                    {data.class_date}
                  </h5>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

const style = {
  title: {
    padding: 25,
    background: " white",
    position: "relative",
    zIndex: 3,
    textAlign: "center",
    width: "100%",
    height: "100%",
    fontSize: 18,
  },
  data: {
    color: "white",
    background: "#2d8b82",
    position: "absolute",
    right: 0,
    padding: "8px 13px",
    borderTopRightRadius: "10px",
  },
};

export default ArtistList;

import axios from "axios";
import React, { useEffect, useState } from "react";
import ImageWrap from "../components/ImageWrap";
import Tabs from "../components/Tabs";
import { BASE_URL } from "../config";

const Schedule = () => {
  const [userId, setUserId] = useState("");
  const [classesList1, setClassesList1] = useState([]);
  const [classesList2, setClassesList2] = useState([]);

  useEffect(() => {
    if (userId === 0) {
      const url = `${BASE_URL}/api/v1/classes-schedule/`;
      axios
        .get(url)
        .then((response) => {
          setClassesList1(response.data.data);
        })
        .catch((err) => console.log(err));
      return;
    }
    const url = `${BASE_URL}/api/v1/classes-schedule/`;
    axios
      .get(url)
      .then((response) => {
        setClassesList2(response.data.data);
      })
      .catch((err) => console.log(err));
    return;
  }, [userId]);

  return (
    <div style={{ margin: "auto" }} className="p-24 schedule">
      <Tabs
        onChange={(i) => {
          setUserId(i);
        }}
        tabs={[
          {
            label: "Class Schedule",
            child: (
              <>
                {" "}
                <h2 className="dashboard-heading title_font title_underline">
                  CLASS SCHEDULE
                </h2>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {classesList1.map((data, index) => (
                    <a
                      style={{ padding: 0 }}
                      className="card elevated_deep m-12 clickable ai-center"
                      target="_blank"
                      rel="noreferrer"
                      href={`${data.info_link_e}`}
                      key={index}
                    >
                      <div
                        style={{
                          width: 300,
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <ImageWrap dataSchedule={data.pdf_cover_pic} />
                      </div>
                    </a>
                  ))}
                </div>
              </>
            ),
          },
          {
            label: "Workshop Schedule",
            child: (
              <>
                {" "}
                <h2 className="dashboard-heading title_font title_underline">
                  WORKSHOPS SCHEDULE
                </h2>{" "}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {classesList2.map((data, index) => (
                    <a
                      style={{ padding: 0 }}
                      className="card elevated_deep m-12 clickable ai-center"
                      target="_blank"
                      rel="noreferrer"
                      href={`${data.info_link_e}`}
                      key={index}
                    >
                      <div
                        style={{
                          width: 300,
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        <ImageWrap dataSchedule={data.pdf_cover_pic} />
                      </div>
                    </a>
                  ))}
                </div>
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Schedule;

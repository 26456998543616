import { ClickAwayListener } from "@mui/material";
import { googleLogout } from "@react-oauth/google";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/home/avatar-1.png";
import dropdown from "../../assets/home/dropdown.png";
import styles from "../../style/dashboard.module.css";
import {
  loadUserDataFromSession,
  clearUserSession,
} from "../../hooks/session-data";

const DashboardNavbar = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const name = loadUserDataFromSession("r2rUserName");

  const handleLogout = () => {
    googleLogout();
    clearUserSession("r2rToken");
    clearUserSession("r2rUserName");
    navigate("/");
    window.location.reload();
  };
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <div className={styles.dashboard_navbar}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <div className={styles.dropdown_button} onClick={handleClick}>
          <div>
            <img width="30px" height="30px" src={avatar} />
            <div type="button">{name ? name.toUpperCase() : "User"}</div>
            <img
              width="15px"
              height="15px"
              src={dropdown}
              style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
            />
          </div>
          {open ? (
            <>
              <div
                className={styles.dropdown}
                onClick={() => handleClickAway()}
              >
                <div onClick={() => handleLogout()}>Logout</div>
                {/* <div onClick={() => navigate("/user/edit-profile")}>
                  Edit Profile
                </div>
                <div onClick={() => navigate("/user/edit-number-email")}>
                  Edit Number/Email
                </div> */}
              </div>
            </>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DashboardNavbar;
